<template>
    <section class="jg-sidemenu">
        <slot name="menu-block"></slot>
    </section>
</template>

<script>
export default {
    name: 'Sidemenu'
}
</script>